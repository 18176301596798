export default {
	name: "WarrantsGuide",
	created () {
		this.$nextTick(function () {
		});
	},
	mounted () {
		this.$nextTick(function () {
		})
	},
	data () {
		return {

		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		shiftContent: function (id) {
			$('#WarrantsGuide').find('.navBox').find('ul').find('li').eq(id).addClass('sign')
			$('#WarrantsGuide').find('.navBox').find('ul').find('li').eq(id).siblings('li').removeClass('sign')
			$('#WarrantsGuide').find('.contentBox').find('.ulBox').find('.liBox').eq(id).show()
			$('#WarrantsGuide').find('.contentBox').find('.ulBox').find('.liBox').eq(id).siblings('.liBox').hide()
		}
	}
};